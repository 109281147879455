export enum AccessoryColor {
  None,
  GalvanizedGreySilver,
  Black,
  BrickRed,
}

export enum Sealant {
  None,
  NockValmRoll300mm,
  NockValmRoll370mm,
  Ventinock,
}

export enum Ventilation {
  None,
  Birdband1m,
  Birdband5mRollBlack,
  Birdband5mRollBrickRed,
  VentilatingBirdband1m,
}

export enum ExposedWeather {
  None,
  Yes,
  No,
}

export enum ConcealedDraining {
  None,
  Yes,
  No,
}

export enum SealingAiring {
  None,
  Yes,
  No,
}

export enum FixingsTile {
  None,
  EasyClip,
  Screw,
  Nail,
  Stormclips,
  SidefoldStaples,
  DiagonalClip,
  DiagonalClipZial,
  RoofhookJP,
  SBracket,
  RooftileHook,
  EasyClip45x70,
}

export enum FixingsNock {
  None,
  NockScrew,
  NockScrewAndStaples,
  NockScrewAndStaplesHansa,
  NockScrewAndNockClip,
}

export enum BattenStep {
  None,
  Step,
  Connected,
}

export enum SecuritySystem {
  None,
  SAFE,
}

export enum Gable {
  None,
  GablePan,
  GableFittings,
  GablePanNA,
  GablePanWide,
}

export enum DrainAerator {
  None,
  Concrete,
  Clay,
  Plate,
}

export enum VentilationHood {
  None,
  Plate,
}

export enum FacadeHeight {
  None = '',
  Less3m = '3m-less',
  Less4m = '4m-less',
  Less5m = '5m-less',
  To58 = '5m-to-8m',
  More8m = '8m-more',
}

export enum BTSBaseRoof {
  None,
  Yes,
  No,
}

export enum NockBoard {
  None,
  Yes,
  No,
}

export enum SnowSlideObstacle {
  None,
  Yes,
  No,
}

export enum ExtraAssortment {
  TakpannorOchTillbehor = 'Takpannor och tillbehör',
  VentilationGenomforing = 'Ventilation/Genomföring',
  // Taksakerhet = "Taksäkerhet",
  TatningLuftning = 'Tätning/Luftning',
  Infastning = 'Infästning',
  Taksakerhet = 'Taksäkerhet',
  OvrigaTillbehor = 'Övriga tillbehör',
}

export enum StepState {
  // Inactive = 'inactive', // can just be null?
  Active = 'active', // Starts as active
  Valid = 'valid', // Field values are valid
  Error = 'error', // Field values are invalid
  Done = 'done', // Step has been completed
}

export enum ValidationSteps {
  Roof = 'roof',
  Tile = 'tile',
  Accessories = 'accessories',
  RoofSecurity = 'roofsecurity',
  Extras = 'extras',

  // RoofType = 'rooftype',
  // LathDistance = 'lathdistance',
  // ConfirmRoof = 'confirmroof',

  RoofAndExtensions = 'roofandextensions',
}

export const accessoryColorMap = {
  [AccessoryColor.GalvanizedGreySilver]: ['Galvad', 'Grå', 'Silver', 'Ljusgrå', 'Mellangrå', 'Platinagrå'],
  [AccessoryColor.Black]: ['Svart'],
  [AccessoryColor.BrickRed]: ['Tegelröd'],
} as { [key in AccessoryColor]: string[] };
